import { useMutation } from '@apollo/react-hooks';
import type { FC } from 'react';
import React, { Fragment, useCallback, memo } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ConfluenceEdition } from '@confluence/change-edition';
import { fg } from '@confluence/feature-gating';
import {
	SPACE_CALENDARS,
	SPACE_QUESTIONS,
	SPACE_QUESTIONS_NATIVE,
	SPACE_CALENDARS_SUBCALENDAR,
} from '@confluence/named-routes';
import { useRouteDataRef, useRouteName } from '@confluence/route-manager';
import { usePageSpaceKey } from '@confluence/page-context';
import type { Route } from '@confluence/route';
import { useSessionData } from '@confluence/session-data';
import { LoadableAfterPaint } from '@confluence/loadable';

import type { SpaceNavigationQuery_spaceSidebarLinks_main } from './__types__/SpaceNavigationQuery';
import { SpaceNavigationQuery } from './SpaceNavigationQuery.graphql';
import { AddSpaceLink } from './AddSpaceLink.experimentalgraphql';
import { RemoveSpaceLink } from './RemoveSpaceLink.experimentalgraphql';
import { useSidebarConfigurationListener } from './useSidebarConfigurationListener';
import { CALENDARS_KEY, QUESTIONS_KEY, QUESTIONS_NATIVE_KEY } from './webItemCompleteKeys';

const TeamCalendarNavigation = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-TeamCalendarNavigation" */ './TeamCalendarNavigation'
			)
		).TeamCalendarNavigation,
});
const QuestionsNavigation = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-QuestionsNavigation" */ './QuestionsNavigation'))
			.QuestionsNavigation,
});

type SimplifiedSpaceLinksProps = {
	links: SpaceNavigationQuery_spaceSidebarLinks_main[];
};

// If pathname is undefined or empty string, then the pathname || "" check will return "/" and match
// the route against "/", otherwise it will match the route against the pathname passed in
export const isRoute = (pathname: string | undefined, route: Route) =>
	Boolean(route.match(pathname || ''));

const isOnSpaceCalendarsRouteArgs = {
	selector: (routeName: string | undefined) =>
		routeName === SPACE_CALENDARS.name || routeName === SPACE_CALENDARS_SUBCALENDAR.name,
};
const isOnSpaceQuestionsRouteArgs = {
	selector: (routeName: string | undefined) => routeName === SPACE_QUESTIONS.name,
};
const isOnSpaceQuestionsNativeRouteArgs = {
	selector: (routeName: string | undefined) => routeName === SPACE_QUESTIONS_NATIVE.name,
};

export const SimplifiedSpaceLinks: FC<SimplifiedSpaceLinksProps> = memo(({ links }) => {
	const [spaceKey] = usePageSpaceKey();
	const routeDataRef = useRouteDataRef();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isLicensed, edition } = useSessionData();
	const isQuestionsNativeEnabled = fg('cc_editions_cq_native');

	const isPremiumEdition = edition === ConfluenceEdition.PREMIUM;

	const [addSpaceLink] = useMutation(AddSpaceLink, {
		refetchQueries: [
			{
				query: SpaceNavigationQuery,
				variables: { spaceKey, isLicensed },
			},
		],
	});
	const [removeSpaceLink] = useMutation(RemoveSpaceLink, {
		refetchQueries: [
			{
				query: SpaceNavigationQuery,
				variables: { spaceKey, isLicensed },
			},
		],
	});

	useSidebarConfigurationListener(addSpaceLink, removeSpaceLink);

	const sendItemClickedAnalytics = useCallback(
		(itemId: any, navdexPointType: any, additionalAttributes = {}) =>
			() => {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'navigationItem',
						actionSubjectId: 'spaceNavigationItem',
						source: 'containerNavigation',
						attributes: {
							navigationLayer: 'container',
							itemId,
							selectedItemPageContext: routeDataRef.current?.routeName,
							navVersion: '3',
							navdexPointType,
							...additionalAttributes,
						},
					},
				}).fire();
			},
		[createAnalyticsEvent, routeDataRef],
	);

	const isOnSpaceCalendarsRoute = useRouteName(isOnSpaceCalendarsRouteArgs);
	const isOnSpaceQuestionsRoute = useRouteName(isOnSpaceQuestionsRouteArgs);
	const isOnSpaceQuestionsNativeRoute = useRouteName(isOnSpaceQuestionsNativeRouteArgs);

	const getBuiltInItemsMapping = useCallback(
		() => ({
			[CALENDARS_KEY]: (
				<TeamCalendarNavigation
					isSelected={isOnSpaceCalendarsRoute}
					onClick={sendItemClickedAnalytics('calendars', undefined, {
						isPremiumEdition,
					})}
				/>
			),
			[QUESTIONS_KEY]: (
				<QuestionsNavigation
					isSelected={isOnSpaceQuestionsRoute}
					onClick={sendItemClickedAnalytics('questions', undefined)}
					href={SPACE_QUESTIONS.toUrl({ spaceKey })}
				/>
			),
			...(isPremiumEdition &&
				isQuestionsNativeEnabled && {
					[QUESTIONS_NATIVE_KEY]: (
						<QuestionsNavigation
							isSelected={isOnSpaceQuestionsNativeRoute}
							onClick={sendItemClickedAnalytics('questions', undefined)}
							href={SPACE_QUESTIONS_NATIVE.toUrl({ spaceKey })}
						/>
					),
				}),
		}),
		[
			isOnSpaceCalendarsRoute,
			sendItemClickedAnalytics,
			isPremiumEdition,
			isOnSpaceQuestionsRoute,
			spaceKey,
			isQuestionsNativeEnabled,
			isOnSpaceQuestionsNativeRoute,
		],
	);

	const renderSimplifiedSpaceLinks = useCallback(() => {
		const builtInItems = getBuiltInItemsMapping();

		return (
			<Fragment>
				{links
					.filter((link): link is SpaceNavigationQuery_spaceSidebarLinks_main =>
						Boolean(link && !link.hidden),
					)
					.sort((a, b) => Number(a.position) - Number(b.position))
					.map(
						({ webItemCompleteKey }) =>
							(webItemCompleteKey && builtInItems[webItemCompleteKey]) ||
							/* Hide everything else */ null,
					)}
			</Fragment>
		);
	}, [getBuiltInItemsMapping, links]);

	return renderSimplifiedSpaceLinks();
});
