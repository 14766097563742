import FeatureGates from '@atlaskit/feature-gate-js-client';

import { addFeatureFlagAccessed } from '@atlassian/react-ufo/feature-flags-accessed';

export const fg: typeof FeatureGates.checkGate = (gateName) => {
	const value = FeatureGates.checkGate(gateName);
	addFeatureFlagAccessed(gateName, value);

	return value;
};
