import type { FC } from 'react';
import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import FeatureGates from '@atlaskit/feature-gate-js-client';
import { LinkItem } from '@atlaskit/side-navigation';
import type { LinkItemProps } from '@atlaskit/side-navigation';
import Tooltip from '@atlaskit/tooltip';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { Box, xcss } from '@atlaskit/primitives';

import { isFedRamp } from '@atlassian/atl-context';

import { usePageSpaceKey } from '@confluence/page-context';
import { PremiumLozengeLoadable } from '@confluence/trial-components';
import { LoadableAfterPaint } from '@confluence/loadable';
import {
	SPACE_ANALYTICS,
	SPACE_ANALYTICS_PLUGIN,
	FEDRAMP_SPACE_ANALYTICS_PLUGIN,
} from '@confluence/named-routes';
import {
	useTrackPremiumFeatureUsage,
	PremiumFeatureUsage,
} from '@confluence/premium-onboarding/entry-points/useTrackPremiumFeatureUsage';

import { cssFn, isRoute } from './SpaceLinks';

const listStyles = xcss({
	marginTop: 'space.0',
});

const GraphBarIcon = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GraphBarIcon" */ './img/GraphBarIcon'))
			.GraphBarIcon,
});

const i18n = defineMessages({
	analyticsTooltip: {
		id: 'side-navigation.space-navigation.analytics.tooltip',
		defaultMessage: 'View analytics for this space',
		description: 'Tooltip message on hover of analytics space link',
	},
	analytics: {
		id: 'side-navigation.container.analytics',
		defaultMessage: 'Analytics',
		description: 'Analytics Menu Item',
	},
});

type AnalyticsNavigationProps = Pick<LinkItemProps, 'onClick'>;

export const AnalyticsNavigation: FC<AnalyticsNavigationProps> = ({ onClick }) => {
	const [spaceKey] = usePageSpaceKey();

	// TODO - CCIR-11: Clean up this feature gate once the analytics migration is complete
	const isAnalyticsUiMigrationEnabled = FeatureGates.checkGate(
		'confluence_frontend_analytics_ui_migration',
	);
	const isFedrampEnv = isFedRamp();
	const analyticsRoute = isAnalyticsUiMigrationEnabled
		? SPACE_ANALYTICS
		: isFedrampEnv
			? FEDRAMP_SPACE_ANALYTICS_PLUGIN
			: SPACE_ANALYTICS_PLUGIN;

	const isSelected = isRoute(location?.pathname, analyticsRoute);

	const { markFeatureAsUsed } = useTrackPremiumFeatureUsage(
		PremiumFeatureUsage.ANALYTICS_VIEW_SPACE,
		/* skip */ !isSelected,
	);
	useEffect(markFeatureAsUsed, [markFeatureAsUsed]);

	return (
		<SpotlightTarget key="space-analytics" name="page-analytics-spotlight">
			<Tooltip position="top" content={<FormattedMessage {...i18n.analyticsTooltip} />}>
				{(tooltipProps) => (
					<Box as="li" xcss={listStyles}>
						<LinkItem
							//	 eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
							cssFn={cssFn}
							key="analytics"
							iconBefore={<GraphBarIcon isSelected={isSelected} />}
							isSelected={isSelected}
							// TODO - CCIR-11: Remove the following comment once the analytics migration is complete:
							// SPACE_ANALYTICS route code will redirect to the correct URL based on the feature gate and FedRAMP environment
							href={SPACE_ANALYTICS.toUrl({ spaceKey })}
							{...tooltipProps}
							onClick={onClick}
							testId="space-navigation-analytics-link"
						>
							<FormattedMessage {...i18n.analytics} />
							<PremiumLozengeLoadable />
						</LinkItem>
					</Box>
				)}
			</Tooltip>
		</SpotlightTarget>
	);
};
