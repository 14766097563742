import { useEffect } from 'react';
import { useMemoOne } from 'use-memo-one';
import { type ApolloClient, type ApolloError } from 'apollo-client';
import { useQuery, type QueryHookOptions } from '@apollo/react-hooks';
import {
	type GQLExtensionContext,
	type GQLExtensionContextExtensionsByTypeVariables,
	type GQLExtensionContextsVariables,
	type GQLExtensionContextsFilter,
	isGQLGatewayError,
	isGQLUnderlyingServiceError,
} from '../graphql/types';
import { type ContextId } from '@atlassian/forge-ui-types';
import { useMetricsContext } from '../../context';
import { getExtensionListQuery, getExtensionListQueryOld } from './lib/queries';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { OPERATIONAL_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { FORGE_UI_ANALYTICS_CHANNEL } from '../../analytics';

export interface QueryVariables {
	contextIds: GQLExtensionContextsVariables['contextIds'];
	type?: GQLExtensionContextExtensionsByTypeVariables['type'];
	filter?: GQLExtensionContextsFilter[];
	locale?: string;
}

export interface QueryData {
	extensionContexts: GQLExtensionContext[];
}

export type UseExtensionQueryHookOptions = QueryHookOptions<QueryData, QueryVariables>;

export type UseExtensionListOptions = QueryVariables & {
	client?: ApolloClient<object>;
	queryOptions?: UseExtensionQueryHookOptions;
	expandAppOwner?: boolean;
	dataClassificationTagIds?: string[];
};

interface CommonHookResult {
	loading: boolean;
	error?: ApolloError;
}

export interface UseExtensionListHookResult extends CommonHookResult {
	extensions: GQLExtensionContext['extensions'];
}

export interface ExtensionListGQLResult<T> {
	data?: {
		extensionContexts?:
			| Array<{
					extensions?: T[];
			  }>
			| Array<{
					extensionsByType?: T[];
			  }>;
	};
	loading: boolean;
}

export const createExtensionListQueryOptions = <T>(
	client: ApolloClient<object> | undefined,
	contextIds: ContextId[],
	queryOptions?: T,
	expandAppOwner?: boolean,
	filter?: GQLExtensionContextsFilter[],
	extensionType?: string,
	dataClassificationTagIds?: string[],
	locale?: string,
): T => {
	const variables = filter
		? {
				contextIds,
				locale,
				filter,
			}
		: {
				contextIds,
				locale,
				type: extensionType,
			};

	const query = filter
		? getExtensionListQuery(expandAppOwner, dataClassificationTagIds)
		: getExtensionListQueryOld(expandAppOwner, undefined, dataClassificationTagIds);

	return {
		variables,
		...queryOptions,
		query,
		client,
		/**
		 * We don't want to set 'ignore' because that discards errors and we need to inspect
		 * them for SLI reporting. We also need 'all' so we don't discard data on errors:http://go/j/AUX-317
		 * https://www.apollographql.com/docs/react/data/error-handling/
		 */
		errorPolicy: 'all',
	} as unknown as T;
};

export const getExtensionsFromGQLResult = <T>({ loading, data }: ExtensionListGQLResult<T>) => {
	if (!loading && data && data.extensionContexts && data.extensionContexts[0]) {
		if ('extensions' in data.extensionContexts[0]) {
			return data.extensionContexts[0].extensions ?? null;
		} else if ('extensionsByType' in data.extensionContexts[0]) {
			return data.extensionContexts[0].extensionsByType ?? null;
		}
	}

	return null;
};

export const useExtensionList = (options: UseExtensionListOptions): UseExtensionListHookResult => {
	const {
		contextIds,
		type: extensionType,
		filter,
		client,
		queryOptions,
		expandAppOwner,
		dataClassificationTagIds,
		locale,
	} = options;
	const contextIdsString = contextIds.sort().join('');
	const startTimeMs = useMemoOne(
		() => performance.now(),
		[contextIdsString, extensionType, client],
	);

	const requestOptions = createExtensionListQueryOptions<UseExtensionQueryHookOptions>(
		client,
		contextIds,
		queryOptions,
		expandAppOwner,
		filter,
		extensionType,
		dataClassificationTagIds,
		locale,
	);
	const query = filter
		? getExtensionListQuery(expandAppOwner)
		: getExtensionListQueryOld(expandAppOwner);

	const { error, loading, data } = useQuery<QueryData, QueryVariables>(
		requestOptions.query || query,
		requestOptions,
	);
	const extensions = getExtensionsFromGQLResult({ loading, data });

	const { page } = useMetricsContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (extensions) {
			createAnalyticsEvent({
				eventType: OPERATIONAL_EVENT_TYPE,
				data: {
					action: 'succeeded',
					actionSubject: 'forge.ui.webClient',
					source: page,
					tags: ['forge'],
					attributes: {
						target: 'useExtensionList',
					},
				},
			}).fire(FORGE_UI_ANALYTICS_CHANNEL);
		}
	}, [extensions, startTimeMs, page, createAnalyticsEvent]);

	useEffect(() => {
		if (
			// if hydration of appOwners (non-critical data) fails but we still have the extensions,
			// we shouldn't count this as a bad event
			!extensions &&
			error &&
			error.graphQLErrors.length > 0
		) {
			const err = error.graphQLErrors.find(
				(err) => isGQLUnderlyingServiceError(err) || isGQLGatewayError(err),
			);

			createAnalyticsEvent({
				eventType: OPERATIONAL_EVENT_TYPE,
				data: {
					action: 'failed',
					actionSubject: 'forge.ui.webClient',
					source: page,
					tags: ['forge'],
					attributes: {
						target: 'useExtensionList',
						errorName: err
							? isGQLUnderlyingServiceError(err)
								? 'componentApi'
								: 'componentGraphQL'
							: 'uncaught',
					},
				},
			}).fire(FORGE_UI_ANALYTICS_CHANNEL);
		}
	}, [extensions, error, page, createAnalyticsEvent]);

	return {
		error,
		extensions: extensions || [],
		loading,
	};
};
