import type { FC, ReactElement } from 'react';
import React, { Fragment, memo, useCallback, useContext, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';
import type { ApolloError } from 'apollo-client';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import memoizeOne from 'memoize-one';

import { NavigationContent, SideNavigation } from '@atlaskit/side-navigation';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { SpotlightTarget, SpotlightManager } from '@atlaskit/onboarding';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Stack, xcss } from '@atlaskit/primitives';

import {
	APP_NAV_CONTAINER_EXPERIENCE,
	BLOG_TREE_TOGGLED_OFF_BLOGS_CHANGEBOARDING,
	BLOG_TREE_EXPERIENCE,
	BLOG_TREE_SPOTLIGHT_EXPERIENCE,
	ExperienceStart,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import { useBooleanFeatureFlag, useSessionData } from '@confluence/session-data';
import { usePageSpaceKey } from '@confluence/page-context';
import { PersistentUpgradeButton } from '@confluence/persistent-upgrade';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { GeneralShortcutListener, SPACE_OVERVIEW_SHORTCUT } from '@confluence/shortcuts';
import { SpaceViewsController } from '@confluence/space-views';
import {
	useSSRPlaceholderReplaceIdProp,
	LoadableAfterPaint,
	LoadableLazy,
} from '@confluence/loadable';
import {
	isSpaceNotFoundError,
	isSpaceRestrictedError,
	RestrictionsDialogQuery,
} from '@confluence/restrictions';
import { getApolloClient, isErrorMarkedAsHandled, markErrorAsHandled } from '@confluence/graphql';
import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import {
	PERFORMANCE_SUBJECT_navigation,
	PERFORMANCE_SUBJECT_sideNavigationFMP,
	PerformanceEnd,
	PerformanceStart,
} from '@confluence/performance';
import {
	getUserPermissionFromQuery,
	GuestRequestToUpgradeSideNav,
} from '@confluence/external-collab-ui';
import { PageSegmentLoadEnd, PageSegmentLoadStart } from '@confluence/browser-metrics';
import { FORGE_MODULE_SPACE_PAGE } from '@confluence/forge-ui/entry-points/ForgeModuleType';
import { useExtensionList } from '@confluence/forge-ui/entry-points/useExtensionList';
import { ShortcutsSection } from '@confluence/space-shortcuts/entry-points/shortcutsSection';
import { MigrateShortcutsChangeboarding } from '@confluence/space-shortcuts/entry-points/migrateShortcutsChangeboarding';
import {
	confluenceSessionStorageInstance as sessionStorage,
	keys as sessionStorageKeys,
} from '@confluence/storage-manager';
import { getMonitoringClient } from '@confluence/monitoring';
import { CONTEXT_PATH } from '@confluence/named-routes';
import { FocusToCurrentPageTreeLinkItemSSRInlineScript } from '@confluence/page-tree/entry-points/FocusToCurrentPageTreeLinkItemSSRInlineScript';
import { PersistentInvitePeopleButton } from '@confluence/persistent-invite-button';
import { PageTreeLoaderOnHover } from '@confluence/page-tree';
import { START_TOUCH } from '@confluence/navdex';
import { SPAViewContext } from '@confluence/spa-view-context';
import {
	useOnboardingState,
	useGetOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { ONBOARDING_SIDE_NAV_STATE_KEYS } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { BlogTree } from '@confluence/blog-tree/entry-points/BlogTree';
import { BlogTreeSpotlight } from '@confluence/blog-tree/entry-points/BlogTreeSpotlight';
import { useIsBlogTreeUnderContentTreeFFEnabled } from '@confluence/blog-tree/entry-points/useIsBlogTreeUnderContentTreeFFEnabled';
import { BlogsToggledOffByPTLChangeboarding } from '@confluence/blog-tree/entry-points/BlogsToggledOffByPTLChangeboarding';
import { BLOG_TREE_METRIC } from '@confluence/blog-tree/entry-points/blogPerfMetric';
import { fg } from '@confluence/feature-gating';

import { SpaceNavigationQuery } from './SpaceNavigationQuery.graphql';
import { SpaceHeader } from './SpaceHeader';
import { SpaceLinks } from './SpaceLinks';
import { SimplifiedSpaceLinks } from './SimplifiedSpaceLinks';
import { getAllAppLinks } from './space-apps-helpers';
import type {
	SpaceNavigationQuery as SpaceNavigationQueryType,
	SpaceNavigationQuery_space,
	SpaceNavigationQuery_spaceSidebarLinks_main,
	SpaceNavigationQuery_spaceSidebarLinks_quick,
	SpaceNavigationQueryVariables,
} from './__types__/SpaceNavigationQuery';
import { SitePermissionType } from './__types__/SpaceNavigationQuery';
import { SpaceHeaderSkeleton } from './SpaceHeaderSkeleton';
import { ContainerSkeleton } from './ContainerSkeleton';
import {
	SPACE_APPS_METRIC,
	SPACE_NAVIGATION_METRIC,
	SPACE_NAVIGATION_QUERY_METRIC,
} from './perf.config';
import { SHORTCUTS_KEY, BLOG_KEY } from './webItemCompleteKeys';

const ErrorView = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ErrorView" */ './ErrorView')).ErrorView,
});

const SpaceApps = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SpaceApps" */ './SpaceApps')).SpaceApps,
});

const SPACE_NAV_ID = 'app-navigation-space-container';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpaceLinksContainer = styled.ul({
	margin: token('space.0', '0px'),
	padding: token('space.0', '0px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginBottom: '18px',
	paddingTop: token('space.025', '2px'),
	listStyle: 'none',
	//eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > li': {
		marginTop: '0',
	},
});

const spaceLinksUnderContentTreeStyles = xcss({
	margin: 'space.0',
	padding: 'space.0',
	paddingTop: 'space.025',
	listStyle: 'none',
});

const SidebarContent = memo(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
	styled.div({
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		paddingBottom: '26px',
		position: 'relative',
	}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpaceNavigationContainer = styled.div({
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	flexGrow: 1,
	flexBasis: 'max-content',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-exit-to] > div > div > div': {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	nav: {
		backgroundColor: token('elevation.surface', N0),
		/* dividers below space header*/
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'div:nth-child(1)::before': {
			backgroundColor: token('elevation.surface', N0),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'div:nth-child(2)::before': {
			right: '15px',
		},
	},
	/* space header*/
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"[data-navheader='true']": {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		padding: '10px 8px 4px 8px !important',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > div': {
			height: '40px',
			margin: '0px',
		},
	},
});

const i18n = defineMessages({
	navRegionLabel: {
		id: 'side-navigation.space-navigation.nav.region.aria.label',
		defaultMessage: 'Space',
		description: 'A label for the left-side navigation region',
	},
});

export type SpaceNavigationProps = {
	isBlogNavigation?: boolean;
	isSpaceSettingsScreen?: boolean;
};

type PreloadSpaceNavigationResult = {
	loading: boolean;
	hasRestrictedError: boolean;
	hasNotFoundError: boolean;
	unhandledError?: ApolloError;
	hasSpace: boolean;
	space?: SpaceNavigationQuery_space;
	name?: string;
	iconPath?: string;
	isSpaceAdmin: boolean;
	homepageId?: string;
	containsExternalCollaborators: boolean;
	main: SpaceNavigationQuery_spaceSidebarLinks_main[];
	quick: SpaceNavigationQuery_spaceSidebarLinks_quick[];
	isUserExternalCollaborator: boolean;
};

// This will stop the space nav metric whenever the query completes,
// regardless of whether Space nav is still mounted when this happens.
const useSpaceNavQueryTiming = (spaceKey: string, isLicensed: boolean) => {
	const apollo = useApolloClient();
	useEffect(() => {
		SPACE_NAVIGATION_QUERY_METRIC.start();
		apollo
			.watchQuery({
				query: SpaceNavigationQuery,
				variables: { spaceKey, isLicensed },
			})
			.subscribe(({ loading }) => {
				if (!loading) {
					SPACE_NAVIGATION_QUERY_METRIC.stop();
				}
			});
	}, [apollo, spaceKey, isLicensed]);
};

const createResult = memoizeOne(
	(
		loading: boolean,
		data: SpaceNavigationQueryType | undefined,
		error: ApolloError | undefined,
	) => {
		const result: PreloadSpaceNavigationResult = {
			loading,
			hasSpace: false,
			isSpaceAdmin: false,
			containsExternalCollaborators: false,
			main: [],
			quick: [],
			isUserExternalCollaborator: false,
			hasRestrictedError: false,
			hasNotFoundError: false,
		};

		if (loading) {
			return result;
		}

		if (error) {
			if (isSpaceRestrictedError(error)) {
				result.hasRestrictedError = true;
			} else if (isSpaceNotFoundError(error)) {
				result.hasNotFoundError = true;
			} else {
				result.unhandledError = error;
			}
			return result;
		}

		result.hasSpace = Boolean(data?.space ?? result.hasSpace);
		result.space = data?.space || undefined;
		result.name = data?.space?.name || undefined;
		result.iconPath = `${CONTEXT_PATH}${data?.space?.icon?.path}`;
		result.isSpaceAdmin = data?.space?.currentUser?.isAdmin ?? result.isSpaceAdmin;
		result.homepageId = data?.space?.homepage?.id || undefined;
		result.containsExternalCollaborators =
			data?.space?.containsExternalCollaborators ?? result.containsExternalCollaborators;

		result.main = data?.spaceSidebarLinks?.main?.filter(isNonNullable) ?? result.main;
		result.quick = data?.spaceSidebarLinks?.quick?.filter(isNonNullable) ?? result.quick;
		result.isUserExternalCollaborator =
			getUserPermissionFromQuery(data) === SitePermissionType.EXTERNAL;

		return result;
	},
);

const usePreloadSpaceNavigation = (spaceKey: string, isLicensed: boolean) => {
	const { loading, data, error } = useQuery<
		SpaceNavigationQueryType,
		SpaceNavigationQueryVariables
	>(SpaceNavigationQuery, {
		errorPolicy: 'all',
		variables: {
			spaceKey,
			isLicensed,
		},
	});
	useSpaceNavQueryTiming(spaceKey, isLicensed);

	const result = createResult(loading, data, error);

	if (loading) return result;

	if (error) {
		if (isSpaceRestrictedError(error)) {
			markErrorAsHandled(error);
		} else if (isSpaceNotFoundError(error)) {
			markErrorAsHandled(error);
		}
	}

	return result;
};

const useOldRestrictionsButtonRefetch = () => {
	const refetch = useCallback((contentId: any) => {
		/**
		 * When the PageTree child component informs us that a page has been
		 * moved in the hierarchy, we execute a fetch of its new permissions
		 * to purge any stale permissions information that the cache may have
		 * held for that page. Not doing so would mean showing stale state for
		 * its padlock icon, and for its restriction dialog, when raised.
		 */
		void getApolloClient().query({
			query: RestrictionsDialogQuery,
			variables: {
				contentId,
			},
			fetchPolicy: 'network-only',
		});
	}, []);

	return refetch;
};

export const SpaceNavigation: FC<SpaceNavigationProps> = memo(
	({ isBlogNavigation, isSpaceSettingsScreen = false }) => {
		const [pageTreeFinishedLoading, setPageTreeFinishedLoading] = useState<boolean>(false);
		const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
		const intl = useIntl();
		const { isLicensed } = useSessionData();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { isNewUser } = useContext(SPAViewContext);
		const { setOnboardingState } = useOnboardingState();

		const isMigrateShortcutsEnabled = useBooleanFeatureFlag(
			'confluence.frontend.migrate-shortcuts-to-smart-links-in-tree',
		);
		const isMigrateShortcutsChangeboardingEnabled = useBooleanFeatureFlag(
			'confluence.frontend.migrate-shortcuts-to-smart-links-in-tree-changeboarding',
		);

		const isSimplifiedSpaceNavEnabled = fg('confluence_space_nav_simplification');

		const isBlogTreeSpotlightEnabled = fg('blog_tree_under_content_tree_spotlight');

		const isBlogTreeUnderContentTreeFFEnabled = useIsBlogTreeUnderContentTreeFFEnabled();

		const [stateSpaceKey] = usePageSpaceKey();

		// @ts-ignore FIXME: `stateSpaceKey` can be `undefined` here, and needs proper handling
		const spaceKey: string = stateSpaceKey;

		const { push, match } = useContext(RoutesContext);

		// On a successful move, tell the restrictions button icon that it may need
		// to be updated
		const onDragDropSuccess = useOldRestrictionsButtonRefetch();

		const onOverviewShortcutTrigger = useCallback(() => {
			push(`/wiki/spaces/${spaceKey}/overview`);
		}, [push, spaceKey]);

		const handleMouseEnter = () => {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'entered',
					actionSubject: 'spaceNavigation',
					source: match?.name,
					attributes: {
						navdexPointType: START_TOUCH,
					},
				},
			}).fire();
		};

		const {
			loading,
			hasRestrictedError,
			hasNotFoundError,
			unhandledError,
			hasSpace,
			name,
			space,
			iconPath,
			isSpaceAdmin,
			homepageId,
			containsExternalCollaborators,
			main,
			quick,
			isUserExternalCollaborator,
		} = usePreloadSpaceNavigation(spaceKey, isLicensed);

		const isBlogsToggledOffChangeboardingEnabled = fg('blogs_toggled_off_changeboarding');

		useEffect(() => {
			const sectionExpandStates = sessionStorage.getItem(
				sessionStorageKeys.SPACE_NAV_SECTIONS_EXPANDED_STATES,
			);
			if (sectionExpandStates == null || sectionExpandStates?.spaceKey != spaceKey) {
				sessionStorage.setItem(sessionStorageKeys.SPACE_NAV_SECTIONS_EXPANDED_STATES, {
					spaceKey,
					contentTree: true,
					shortcuts: true,
					apps: true,
				});
			}
		}, [spaceKey]);

		const {
			loading: loadingForgeApps,
			extensions: forgeApps,
			error: forgeError,
		} = useExtensionList({
			moduleType: FORGE_MODULE_SPACE_PAGE,
		});

		if (forgeError && !isErrorMarkedAsHandled(forgeError)) {
			getMonitoringClient().submitError(forgeError, {
				attribution: Attribution.ECOSYSTEM,
			});
			markErrorAsHandled(forgeError);
		}

		const { allAppLinks, allVisibleAppLinks, shouldRenderSpaceApps } = getAllAppLinks(
			spaceKey,
			main,
			forgeApps,
			isSpaceAdmin,
		);

		const shortcutsHidden =
			main.filter(
				({ webItemCompleteKey, hidden }) => webItemCompleteKey === SHORTCUTS_KEY && hidden,
			).length > 0;

		// check if blogs should show. Blogs are default off if the webitem does not exist.
		const blogsWebItem = main.find(({ webItemCompleteKey }) => webItemCompleteKey === BLOG_KEY);
		const blogsHidden = !blogsWebItem || blogsWebItem.hidden === true;

		// We only want to show "we did a little tidying" changeboarding card for spaces that had blogs on by default before we force toggled them off.
		// This means: show changeboarding if the changeboarding FF is on AND the space has never had a blogs visibility preference intentionally set ("!blogsWebItem") AND blogs is hidden AND the user is a space admin AND
		// the space was created before we started disabling blogs by default everywhere.
		// Since disabling blogs starts rolling out 9/18 to prod, we will make that the cut off mark. No spaces created after 9/18 will see the changeboarding card.
		const showWeToggledOffBlogsChangeboarding =
			isBlogsToggledOffChangeboardingEnabled &&
			!blogsWebItem &&
			blogsHidden &&
			isSpaceAdmin &&
			!isNewUser &&
			new Date(space?.history?.createdDate ?? new Date()) < new Date('2024-09-18');

		// Determine whether or not the user has seen the changeboarding modal for migrating shortcuts
		const {
			data: migrationOnboardingStateData,
			loading: migrationOnboardingStateLoading,
			error: migrationOnboardingStateError,
		} = useGetOnboardingState(
			Object.values({ ...ONBOARDING_SIDE_NAV_STATE_KEYS }),
			!isMigrateShortcutsEnabled || !isMigrateShortcutsChangeboardingEnabled,
		);

		// check if shortcuts should show
		const { migrateShortcutsChangeBoardingSeen } = deserializeState(migrationOnboardingStateData);
		const showShortcutsSection =
			!loading && !shortcutsHidden && !(isMigrateShortcutsEnabled && quick.length === 0);
		const showMigrateShortcutsChangeboarding =
			!loading &&
			!shortcutsHidden &&
			isMigrateShortcutsEnabled &&
			isMigrateShortcutsChangeboardingEnabled &&
			!migrateShortcutsChangeBoardingSeen &&
			!migrationOnboardingStateError &&
			!migrationOnboardingStateLoading &&
			!isSpaceAdmin &&
			quick.length === 0;

		const onContentTreeLoadComplete = useCallback(() => {
			if (isBlogTreeUnderContentTreeFFEnabled || isBlogTreeSpotlightEnabled) {
				setPageTreeFinishedLoading(true);
			}
		}, [isBlogTreeUnderContentTreeFFEnabled, isBlogTreeSpotlightEnabled]);

		let sideNavContent: ReactElement;
		if (unhandledError || hasRestrictedError || hasNotFoundError || (!loading && !hasSpace)) {
			sideNavContent = (
				<ErrorBoundary
					attribution={Attribution.DISCO}
					attributes={{
						errorBoundaryId: 'SpaceNavigation-content-error',
					}}
				>
					<ErrorView
						unhandledError={unhandledError}
						hasRestrictedError={hasRestrictedError}
						hasNotFoundError={hasNotFoundError}
					/>
				</ErrorBoundary>
			);
		} else {
			sideNavContent = (
				<ErrorBoundary
					attribution={Attribution.DISCO}
					attributes={{
						errorBoundaryId: 'SpaceNavigation-content',
					}}
				>
					{loading || !name ? (
						<SpaceHeaderSkeleton />
					) : (
						<SpaceHeader
							spaceId={space?.id || ''}
							spaceKey={spaceKey}
							spaceName={name}
							space={space}
							iconPath={iconPath}
							homepageId={homepageId}
							containsExternalCollaborators={containsExternalCollaborators}
							isUserExternalCollaborator={isUserExternalCollaborator}
						/>
					)}
					<NavigationContent>
						<SidebarContent data-vc="space-navigation-sidebar-content">
							{!loading ? <ExperienceSuccess name={APP_NAV_CONTAINER_EXPERIENCE} /> : <Fragment />}
							{showWeToggledOffBlogsChangeboarding && (
								<Fragment>
									<ExperienceStart name={BLOG_TREE_TOGGLED_OFF_BLOGS_CHANGEBOARDING} />
									<BlogsToggledOffByPTLChangeboarding spaceKey={spaceKey} />
								</Fragment>
							)}
							{!isSimplifiedSpaceNavEnabled && (
								<SpaceLinksContainer data-testId="space-links-above-content-tree">
									{loading ? (
										<ContainerSkeleton header={false} />
									) : (
										<SpaceLinks
											isSpaceAdmin={isSpaceAdmin}
											links={main}
											isSpaceSettingsScreen={isSpaceSettingsScreen}
											blogsHidden={blogsHidden}
										/>
									)}
								</SpaceLinksContainer>
							)}
							{showShortcutsSection && (
								<ShortcutsSection
									isSpaceAdmin={isSpaceAdmin}
									spaceKey={spaceKey}
									links={quick}
									spaceId={space?.id || null}
								/>
							)}
							{showMigrateShortcutsChangeboarding && (
								<MigrateShortcutsChangeboarding
									onDismissClick={() => {
										setOnboardingState({
											key: ONBOARDING_SIDE_NAV_STATE_KEYS.MIGRATE_SHORTCUTS_CHANGEBOARDING_SEEN,
											value: 'true',
										});
									}}
								/>
							)}
							{!loading ? (
								<div data-testid="pageTree" onMouseEnter={PageTreeLoaderOnHover.hydrateOnHover}>
									<SpaceViewsController
										key={spaceKey} // Remount on space change to reset Space views state
										homepageId={homepageId}
										onDragDropSuccess={onDragDropSuccess}
										isPeekingFromBlogs={isBlogNavigation}
										onContentTreeLoadComplete={onContentTreeLoadComplete}
									/>
								</div>
							) : (
								<Fragment />
							)}
							{!loading && isBlogTreeUnderContentTreeFFEnabled && !blogsHidden && (
								<SpotlightManager blanketIsTinted={false}>
									<PageSegmentLoadStart metric={BLOG_TREE_METRIC} />
									<ExperienceStart name={BLOG_TREE_EXPERIENCE} />
									<BlogTree spaceKey={spaceKey} pageTreeFinishedLoading={pageTreeFinishedLoading} />
									{isBlogTreeSpotlightEnabled &&
										pageTreeFinishedLoading &&
										!isNewUser &&
										isLicensed && (
											<Fragment>
												<ExperienceStart name={BLOG_TREE_SPOTLIGHT_EXPERIENCE} />
												<BlogTreeSpotlight />
											</Fragment>
										)}
								</SpotlightManager>
							)}
							{isSimplifiedSpaceNavEnabled && (
								<Stack
									as="ul"
									xcss={spaceLinksUnderContentTreeStyles}
									testId="space-links-under-content-tree"
								>
									{loading ? (
										<ContainerSkeleton header={false} />
									) : (
										<SimplifiedSpaceLinks links={main} />
									)}
								</Stack>
							)}
							{!loading &&
							(process.env.REACT_SSR || window.__SSR_RENDERED__ || !loadingForgeApps) &&
							shouldRenderSpaceApps ? (
								<ErrorBoundary attribution={Attribution.ECOSYSTEM}>
									<PageSegmentLoadStart metric={SPACE_APPS_METRIC} />
									<SpaceApps
										spaceKey={spaceKey}
										isSpaceAdmin={isSpaceAdmin}
										allAppLinks={allAppLinks}
										allVisibleAppLinks={allVisibleAppLinks}
									/>
								</ErrorBoundary>
							) : (
								<Fragment />
							)}
						</SidebarContent>
					</NavigationContent>
					<PersistentUpgradeButton />
					<PersistentInvitePeopleButton source="pageTree" />
					<GuestRequestToUpgradeSideNav />
				</ErrorBoundary>
			);
		}

		return (
			<ErrorBoundary
				attribution={Attribution.DISCO}
				attributes={{
					errorBoundaryId: 'SpaceNavigation-all',
				}}
			>
				<SpotlightTarget name="live-pages-changeboarding.content-tree">
					<SpaceNavigationContainer
						data-testid={SPACE_NAV_ID}
						data-vc="space-navigation"
						onMouseEnter={handleMouseEnter}
						{...ssrPlaceholderIdProp}
					>
						<PerformanceStart
							subject={PERFORMANCE_SUBJECT_navigation}
							subjectId="SpaceViewLoading"
						/>
						<SideNavigation label={intl.formatMessage(i18n.navRegionLabel)}>
							{sideNavContent}
						</SideNavigation>
						<GeneralShortcutListener
							key="overview-shortcut"
							accelerator={SPACE_OVERVIEW_SHORTCUT}
							listener={onOverviewShortcutTrigger}
						/>
						<PerformanceEnd
							subject={PERFORMANCE_SUBJECT_sideNavigationFMP}
							subjectId="SideNavigationFMP"
							includeFeatureFlags
						/>
						{!loading && <PageSegmentLoadEnd key={spaceKey} metric={SPACE_NAVIGATION_METRIC} />}
						{unhandledError && <ErrorDisplay error={unhandledError} />}
						{(process.env.REACT_SSR || window.__SSR_RENDERED__) && (
							<FocusToCurrentPageTreeLinkItemSSRInlineScript />
						)}
					</SpaceNavigationContainer>
				</SpotlightTarget>
			</ErrorBoundary>
		);
	},
);

function isNonNullable<T extends object | null | undefined>(x: T): x is NonNullable<T> {
	return Boolean(x);
}
